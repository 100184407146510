<template>
  <div class="100vh mt-15">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            class="mb-16 mx-auto"
            width="185px"
            height="60px"
            src="@/assets/logo/facenet.png"
          ></v-img>
          <v-card rounded="md" flat class="wd-486 ma-auto pa-4">
            <v-card-title
              class="font-24 fw-600 greyColor--text mb-3 justify-center"
              >Verify Your Account</v-card-title
            >
            <v-card-subtitle class="font-14 fw-400 secondary--text mb-4">
              <p class="text-center">
                Enter a verification code to verify account
              </p>
            </v-card-subtitle>
            <v-card-text>
              <div class="d-flex justify-center">
                <div style="max-width: 200px">
                  <v-otp-input
                    length="6"
                    width="100px"
                    type="number"
                    v-model="otp"
                  ></v-otp-input>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="pa-3">
              <v-btn
                color="primary"
                height="50px"
                block
                depressed
                @click="verifyEmail()"
                :loading="isLoading"
                class="textColor--text ml-1 text-transform-none"
                >Verify</v-btn
              >
            </v-card-actions>
            <back-to-login />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";
import { sidebarItems } from "@/constants/global";
import { showErrorMessage, showNotificationBar } from "@/utils/showError";
import { mapGetters } from "vuex";

export default {
  components: { BackToLogin },
  data() {
    return {
      isLoading: false,
      otp: null,
      dashboardPermission: null,
      permissions: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["getPermissions"]),
  },
  methods: {
    /**
     * Method to verify email using OTP, in case of 2fa enabled
     */
    verifyEmail() {
      this.isLoading = true;
      ApiService.PostRequest("/merchant/two-factor-auth", {
        otp: this.otp,
        otp_signature: this.$store.getters.getOtpSignature,//use store value
      })
        .then((response) => {
          if (window.location.hostname === "localhost") {
            localStorage.setItem("token", response.data.result.data.token);
          }
          const merchant = response.data.result.data;
          localStorage.setItem("token", merchant.token);
          this.$store.dispatch("LOGIN", merchant);
          
          this.getPermissions.forEach((allPermissions) => {
            this.permissions.push(allPermissions.name);
            localStorage.setItem("userPermissions", this.permissions);
          });
          showNotificationBar("success", response.data.message);
          //Set Customization
					this.$store.commit('SET_CUSTOMIZATION', merchant?.customizations)
          //Document Verification Allowed 
          const documentVerification = merchant?.customizations?.document_verification?.document_verification_allowed;
            this.$store.dispatch("DOCUMENT_VERIFICATION_ALLOWED",documentVerification);	
            const themeCustomization = merchant?.customizations?.liveness?.enable_theme_customization;	
            this.$store.dispatch("ENABLE_THEME_CUSTOMIZATION",themeCustomization);
          this.renderSideBar();
          this.$store.dispatch("SET_SIDEBAR_ITEM", {
            dashboard: this.dashboardPermission,
            items: this.items,
          });

          this.$store.dispatch(
            "SET_FIRST_ROUTE",
            this.dashboardPermission == true
              ? {
                  to: "/dashboard",
                  title: "Dashboard",
                  img: require("@/assets/icons/dashboard.svg"),
                }
              : this.items[0]
          );

          this.$router.push(
            this.dashboardPermission == true ? "/dashboard" : this.items[0].to
          );
        })
        .catch((error) => {
          showErrorMessage(error);
          this.isLoading = false;
        });
    },

    /**
     * Renders the sidebar by filtering the sidebar items based on user permissions.
     * It checks if the user has permission to view the dashboard and then iterates
     * through the sidebar items to filter out the items the user has permission to view.
     * Only the categories with permitted items will be included in the final sidebar structure.
     *
     * @method renderSideBar
     */
    renderSideBar() {
      this.dashboardPermission = this.hasPermission("view-dashboard-merchant");
      const allowedSidebarItems = [];
      sidebarItems.forEach((category) => {
        const allowedCategoryItems = [];

        Object.values(category).forEach((items) => {
          items.forEach(([key, value]) => {
            if (Array.isArray(key)) {
              let hasPermission = false;
              key.forEach((permissionName) => {
                if (this.hasPermission(permissionName)) hasPermission = true;
              });
              if (hasPermission) allowedCategoryItems.push({ ...value });
            } else if (this.hasPermission(key))
              allowedCategoryItems.push({ ...value });
          });
        });

        if (allowedCategoryItems.length > 0) {
          allowedSidebarItems.push({
            [Object.keys(category)[0]]: allowedCategoryItems,
          });
        }
      });

      this.items = allowedSidebarItems;
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  min-height: 30px !important;
}
</style>
